<template>
  <div class="userMenu">
    <div class="my-details">
      <div class="head-box">
        <img v-if="avatar" :src="avatar" />
        <img v-else src="../assets/icon/avatar.png" />
        <div>
          <p v-text="nickname?nickname:'1000'+user_id"></p>
          <img src="../assets/icon/write.png" class="click" @click.stop="toUserInfoPage" />
        </div>
        <p v-text="area === 1 ? '大陆':'香港'">大陆</p>
      </div>

      <div class="way-box">
        <p
          v-if="area === 1 "
          class="click"
          :class="{'active':currentPath=== '/user/record'}"
          @click.stop="onRecordClick()"
        >客户备案</p>

        <p
          v-for="(item,index) in list"
          :key="index"
          class="click"
          :class="{'active':currentPath===item.path}"
          v-text="item.name"
          @click.stop="leftMenuClick(item)"
        >我的订单</p>
      </div>
    </div>
  </div>
</template>
<script>
import { MessageBox } from 'element-ui'
export default {
  name: 'UserMenu',
  data() {
    return {
      avatar: null,
      nickname: null,
      area: null,
      currentPath: this.$route.path,
      user_id: 0,
      list: this.updateListData(),
    }
  },
  created() {
    var self = this
    eventBus.$on('updateUserInfo', function (data) {
      // self.getUserInfo();
      self.avatar = data.avatar
      self.nickname = data.user_name
    })

    // if (self.currentPath != '/order/list') {
    //     var userinfo = sessionStorage.getItem('userinfo');
    //     userinfo = JSON.parse(userinfo);
    // }

    var userinfo = sessionStorage.getItem('userinfo')
    userinfo = JSON.parse(userinfo)
    if (userinfo) {
      self.nickname = userinfo.user_name
      self.avatar = userinfo.avatar
      self.area = userinfo.local
      self.user_id = userinfo.id
      var locale = userinfo.local == 1 ? 'zhCHS' : 'zhCHT'
      self.$i18n.locale = locale
      self.updateListData()
    } else {
      this.getUserInfo()
    }
  },
  methods: {
    updateListData: function () {
      this.list = [
        { name: this.$t('menu.order'), path: '/order/list' },
        { name: this.$t('menu.money'), path: '/user/balance' },
        { name: this.$t('menu.adderss'), path: '/user/address' },
        { name: this.$t('menu.login'), path: '/user/password' },
        { name: this.$t('menu.pay'), path: '/user/paypassword' },
        { name: this.$t('menu.numb'), path: null, fn: 'fnContactService' },
        { name: this.$t('menu.leaving'), path: '/user/messageList' },
        { name: this.$t('menu.quit'), path: null, fn: 'fnLogout' },
      ]
    },
    getUserInfo() {
      var self = this
      self.common.httpPost('/api/user/getUserInfo').then((ret) => {
        if (ret.code == 1) {
          self.nickname = ret.data.user_name
          self.avatar = ret.data.avatar
          self.area = ret.data.local
          var locale = ret.data.local == 1 ? 'zhCHS' : 'zhCHT'
          self.$i18n.locale = locale
          sessionStorage.setItem('userinfo', JSON.stringify(ret.data))
          self.updateListData()
        }
      })
    },
    toUserInfoPage() {
      this.$router.push({
        path: '/user/info',
        query: {
          avatar: this.avatar,
          nickname: this.nickname,
        },
      })
    },
    leftMenuClick(item) {
      if (item.path === null && item.fn) eval('this.' + item.fn + '()')
      else this.$router.push(item.path)
    },

    onRecordClick() {
      this.$router.push('/user/record')
    },

    fnContactService: function () {
      this.$router.push('/feedback')
    },
    fnLogout: function () {
      var self = this
      MessageBox({
        title: this.$t('menu.tips'),
        message: this.$t('menu.sure_exit'),
        confirmButtonText: this.$t('menu.yes'),
        showCancelButton: true,
        cancelButtonText: this.$t('menu.no'),
        type: 'warning',
        callback(action) {
          if (action === 'confirm') {
            localStorage.removeItem('Wx1e2PtoP')
            localStorage.removeItem('local')
            sessionStorage.removeItem('userinfo')
            window.location.href = '/dist'
          }
        },
      })
    },
  },
}
</script>
<style scoped>
.my-details {
  width: 196px;
  max-height: 1000px;
  min-height: 600px;
  border: 1px solid rgba(182, 182, 182, 0.7);
}

.head-box {
  margin: 30px 10px 5px 10px;
  text-align: center;
  border-bottom: 1px dashed rgba(151, 151, 151, 0.7);
}

.head-box > img {
  height: 100px;
  width: 100px;
  border: 1px solid rgba(156, 192, 210, 0.7);
  border-radius: 50px;
}

.head-box div {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.head-box div p {
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 18px;
}

.head-box div img {
  height: 10px;
  width: 10px;
  margin-left: 5px;
}

.head-box > p {
  font-size: 12px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 12px;
  margin-bottom: 33px;
}

.way-box {
  margin-left: 50px;
}

.way-box p:hover {
  color: #297fab;
}

.way-box p {
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 16px;
  margin-top: 24px;
}

.way-box p.active {
  color: #297fab;
}
</style>