<!-- 我的收货地址 -->
<template>
  <div class="userAddress center-wrap">
    <div class="page">
      <div class="left-box">
        <v-user-menu></v-user-menu>
      </div>
      <div class="body-box">
        <div class="head-name">
          <div class="title-box">
            <p v-text="$t('address.user')">编辑个人资料</p>
            <div
              class="click"
              @click.stop="addAddressMaskShow= true"
              v-text="$t('address.new')"
            >新建地址</div>
          </div>
          <div class="address-list-box" v-if="list && list.length">
            <div class="address" v-for="item,index in list" :key="index">
              <div class="user-address-body">
                <div>
                  <h1 class="h1" v-text="$t('address.people')">收货人：</h1>
                  <p v-text="item.name">李建</p>
                  <h2>
                    <span
                      class="click"
                      @click.stop="editAddress(index)"
                      v-text="$t('address.edit')"
                    >编辑</span>/
                    <span
                      class="click"
                      @click.stop="removeAddress(index)"
                      v-text="$t('address.delete')"
                    >删除</span>
                  </h2>
                </div>
                <div>
                  <h1 class="h1" v-text="$t('address.number')">联系方式：</h1>
                  <p v-text="item.mobile">13982374584</p>
                </div>
                <div>
                  <h1 class="h1" v-text="$t('address.address')">收货地址：</h1>
                  <p>{{item.province + item.city + item.area}} {{item.address}}</p>
                </div>
              </div>
              <div class="address-choice click" @click.stop="setDefaultAddress(index)">
                <img src="../assets/icon/yes-dot.png" v-if="item.is_default" />
                <img src="../assets/icon/no-dot.png" v-else />
                <p v-text="$t('address.default')">设为默认</p>
              </div>
            </div>
          </div>
          <div v-else class="empty">
            <img src="../assets/icon/address-empty.png" alt />
            <p v-text="$t('address.no')">暂无收货地址</p>
          </div>
        </div>
      </div>
    </div>
    <v-add-address
      v-if="addAddressMaskShow"
      @close="addAddressMaskShow=false"
      @addAddressSuccess="addAddressSuccess"
      @editAddressSuccess="editAddressSuccess"
      :editAdderss="editAdderss"
    ></v-add-address>
  </div>
</template>
<script>
import { MessageBox } from 'element-ui'
import UserMenu from '../components/userMenu.vue'
import AddAddress from '../components/addAddress.vue'
export default {
  name: 'UserAddress',
  data() {
    return {
      list: [],
      addAddressMaskShow: false,
      editAdderss: null,
      editAdderssIndex: null,
    }
  },
  created() {
    this.myAddressList()
  },
  methods: {
    editAddress(index) {
      this.editAdderss = this.list[index]
      this.editAdderssIndex = index
      this.addAddressMaskShow = true
    },
    /**
     * 编辑收货地址成功
     * @param  {[type]} address
     */
    editAddressSuccess(address) {
      this.addAddressMaskShow = !1
      this.editAdderss = null
      this.$set(this.list, this.editAdderssIndex, address)
      this.editAdderssIndex = null
    },

    /// 请求我的收货地址
    myAddressList() {
      var self = this
      self.common.httpPost('/api/user/myAddressList').then((ret) => {
        if (ret.code == 1) {
          self.list = ret.data
        }
      })
    },

    /**
     * 设置默认收货地址
     */
    setDefaultAddress(index) {
      var self = this
      if (self.list[index].is_default) return
      self.common
        .httpPost('/api/user/setDefaultAddress', {
          id: self.list[index].id,
        })
        .then((ret) => {
          self.common.showMessage(ret.msg, ret.code == 1 ? 'success' : 'error')
          if (ret.code == 1) {
            for (var i = 0; i < self.list.length; i++) {
              var address = self.list[i]
              if (address.is_default) {
                address.is_default = 0
                self.$set(self.list, i, address)
              }
            }
            var address = self.list[index]
            address.is_default = 1
            self.$set(self.list, index, address)
          }
        })
    },
    /**
     * 删除收货地址
     * @param  {[type]} index [description]
     * @return {[type]}       [description]
     */
    removeAddress(index) {
      var self = this
      MessageBox({
        title: self.$t('order.pay_pop'), //提示
        message: self.$t('order.no_record'), //此操作将永久删除该记录, 是否继续?
        confirmButtonText: self.$t('cart.define'), //确定
        showCancelButton: true,
        cancelButtonText: self.$t('cart.cancel'), //取消
        type: 'warning',
        callback(action) {
          if (action === 'confirm') {
            self.common
              .httpPost('/api/user/delAddress', {
                id: self.list[index].id,
              })
              .then((ret) => {
                self.common.showMessage(
                  ret.msg,
                  ret.code == 1 ? 'success' : 'error'
                )
                if (ret.code == 1) self.list.splice(index, 1)
              })
          }
        },
      })
    },
    addAddressSuccess(address) {
      this.addAddressMaskShow = !1
      this.list.push(address)
    },
  },
  components: {
    'v-add-address': AddAddress,
    'v-user-menu': UserMenu,
  },
}
</script>
<style scoped>
.page {
  margin: 20px 83px 44px 83px;
  display: flex;
}

.left-box {
  width: 196px;
  max-height: 1000px;
  min-height: 550px;
}

.body-box {
  width: 982px;
  margin-left: 22px;
}

.head-name {
  border: 1px solid rgba(182, 182, 182, 0.7);
}

.title-box {
  display: flex;
  align-items: center;
  height: 50px;
  background: rgba(247, 246, 246, 1);
}

.empty {
  height: auto;
}

.title-box p {
  font-size: 16px;
  font-weight: 500;
  color: rgba(153, 153, 153, 1);
  line-height: 50px;
  margin-left: 30px;
}

.title-box div {
  width: 108px;
  height: 32px;
  background: rgba(255, 255, 255, 1);
  border-radius: 3px;
  border: 1px solid rgba(204, 204, 204, 0.7);
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 32px;
  text-align: center;
  margin-left: 30px;
}

.address {
  height: 135px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 80px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.7);
}

.address:last-child {
  border-bottom: 0;
}

.user-address-body div {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.user-address-body div:last-child {
  margin-bottom: 0px;
}

h1 {
  width: 76px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 14px;
}

.h1 {
  padding-bottom: 0px !important;
}

.user-address-body div p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 14px;
}

h2 {
  font-size: 12px;
  font-weight: 400;
  color: rgba(41, 127, 171, 1);
  line-height: 14px;
  margin-left: 42px;
}

.address-choice {
  display: flex;
  align-items: center;
}

.address-choice img {
  height: 15px;
  width: 15px;
  margin-right: 10px;
}

.address-choice p {
  font-size: 12px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  line-height: 24px;
}
</style>